import React from 'react';
import Navbar from '../components/Navbar';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../css/tarjetas.css";

function Tarjetas() {
  const backgroundImages = [
    'https://images.unsplash.com/photo-1614680376593-902f74cf0d41',
    'https://images.unsplash.com/photo-1615370282496-cfcc15923c68',
    'https://images.unsplash.com/photo-1542323228-002ac256e7b8'
  ];

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true,
    arrows: false,
    pauseOnHover: false
  };

  return (
    <div className="tarjetas-container">
      <div className="background-slider">
        <Slider {...sliderSettings}>
          {backgroundImages.map((image, index) => (
            <div key={index} className="slide-background">
              <div 
                className="slide-image" 
                style={{ 
                  backgroundImage: `linear-gradient(rgba(25, 14, 95, 0.7), rgba(25, 14, 95, 0.7)), url(${image}?q=80&w=1920)`
                }}
              />
            </div>
          ))}
        </Slider>
      </div>
      <Navbar />
      <div className="content">
        <h1>Mastercard Black Internacional</h1>
        <div className="tarjetas-section">
          <div className="tarjetas-card">
            <h2>Beneficios</h2>
            <p>Como empresa líder en soluciones tecnológicas, ofrecemos a todos nuestros socios una tarjeta
            Mastercard Black con las siguientes caracteristicas.</p>
            
            <div className="tarjetas-features">
              <div className="feature">
                <h3>🌎 Aceptación Global</h3>
                <p>Reconocida y aceptada globalmente para una experiencia de uso sin restricciones</p>
              </div>
              <div className="feature">
                <h3>✈️ Beneficios Premium</h3>
                <p>Acceso a servicios exclusivos, como salas VIP en aeropuertos (varían según
                  el país).</p>
              </div>
              <div className="feature">
                <h3>💳 Transacciones Ilimitadas</h3>
                <p>Sin restricciones en la cantidad de transacciones que se pueden realizar</p>
              </div>
              <div className="feature">
                <h3>🔄 Recargas Ilimitadas</h3>
                <p>Los usuarios pueden recargar su tarjeta tantas veces como deseen.</p>
              </div>
              <div className="feature">
                <h3>🏧 Cajeros Globales</h3>
                <p>Acceso a efectivo en cualquier parte del mundo con la misma facilidad</p>
              </div>
              <div className="feature">
                <h3>🔒 Seguridad Avanzada</h3>
                <p>Protección completa en todas tus transacciones con tecnología de punta</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="home-footer">
        <p>© 2024 Sistema de Pagos. Todos los derechos reservados.</p>
      </footer>
    </div>
  );
}

export default Tarjetas; 