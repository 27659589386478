import React from 'react';
import Navbar from '../components/Navbar';
import "../css/quienes-somos.css";
import { BiWallet } from 'react-icons/bi';
import { RiMastercardFill } from 'react-icons/ri';
import { GiPowerLightning } from 'react-icons/gi';
import { BsCashStack } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

function QuienesSomos() {
  const navigate = useNavigate();

  return (
    <div className="quienes-somos-container">
      <Navbar />
      <div className="content">
        <h1 className="main-title">¿QUIÉNES SOMOS?</h1>
        <div className="info-section">
          <div className="values-grid">
            <div className="value-card" onClick={() => navigate('/wallet')}>
              <BiWallet className="card-icon" />
              <h3>Crypto Wallet Descentralizada</h3>
              <p>Somos una empresa fintech innovadora comprometida con transformar la experiencia financiera de nuestros usuarios en el mundo digital. Nuestra Crypto Wallet descentralizada aborda el desafío de convertir criptomonedas a moneda fiat de forma rápida, segura y accesible.</p>
            </div>

            <div className="value-card" onClick={() => navigate('/tarjetas')}>
              <RiMastercardFill className="card-icon" />
              <h3>Tarjeta Mastercard Black</h3>
              <p>Complementamos nuestra solución con una tarjeta Mastercard Black internacional, que facilita las transacciones y ofrece beneficios exclusivos, como acceso a salones VIP en aeropuertos y otros servicios premium.</p>
            </div>

            <div className="value-card no-click">
              <GiPowerLightning className="card-icon" />
              <h3>Empoderamiento Financiero</h3>
              <p>Nuestra misión es empoderar a nuestros usuarios con herramientas financieras modernas que simplifican y optimizan la gestión de sus activos digitales, permitiéndoles disfrutar de una vida sin fronteras en un entorno globalizado.</p>
            </div>

            <div className="value-card" onClick={() => navigate('/remesas')}>
              <BsCashStack className="card-icon" />
              <h3>Remesas Eficientes</h3>
              <p>Facilitamos la posibilidad de enviar remesas de forma inmediata y eficiente a familiares y colaboradores que posean nuestra tarjeta, reduciendo notablemente las altas comisiones asociadas a este tipo de transacciones.</p>
            </div>
          </div>
        </div>
      </div>
      <footer className="home-footer">
        <p>© 2024 Sistema de Pagos. Todos los derechos reservados.</p>
      </footer>
    </div>
  );
}

export default QuienesSomos; 