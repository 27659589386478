import React from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import "../css/home.css";

function Home() {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate('/login');
  };

  return (
    <div className="home-container">
      <Navbar />
      <main className="hero-section">
        <div className="hero-content">
          <h1>Sistema de Pagos Seguro y Confiable</h1>
          <p className="hero-description">
            La manera más rápida y segura de realizar tus transacciones en línea
          </p>
          <button onClick={handleLogin} className="cta-button">
            Comenzar Ahora
          </button>
        </div>

        <div className="features-section">
          <div className="feature-card">
            <img 
              src="https://images.unsplash.com/photo-1563013544-824ae1b704d3?w=500&q=80" 
              alt="Pagos Seguros"
              className="feature-image"
            />
            <div className="feature-icon">💳</div>
            <h3>Pagos Seguros</h3>
            <p>Transacciones protegidas y encriptadas</p>
          </div>

          <div className="feature-card">
            <img 
              src="https://images.unsplash.com/photo-1554224155-6726b3ff858f?w=500&q=80" 
              alt="Rápido y Fácil"
              className="feature-image"
            />
            <div className="feature-icon">⚡</div>
            <h3>Rápido y Fácil</h3>
            <p>Procesa pagos en segundos</p>
          </div>

          <div className="feature-card">
            <img 
              src="https://images.unsplash.com/photo-1551288049-bebda4e38f71?w=500&q=80" 
              alt="Seguimiento"
              className="feature-image"
            />
            <div className="feature-icon">📊</div>
            <h3>Seguimiento</h3>
            <p>Historial completo de transacciones</p>
          </div>
        </div>
      </main>

      <footer className="home-footer">
        <p>© 2024 Sistema de Pagos. Todos los derechos reservados.</p>
      </footer>
    </div>
  );
}

export default Home; 