import React from 'react';
import logo from "../images/logo.png";

function Footer() {
  return (
    <footer className="home-footer">
      <img 
        src={logo} 
        alt="Logo" 
        className="footer-logo"
      />
      <p>© 2024 Sistema de Pagos. Todos los derechos reservados.</p>
    </footer>
  );
}

export default Footer; 