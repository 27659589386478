import React from 'react';
import Navbar from '../components/Navbar';
import "../css/wallet.css";
import App from "react-modal-playground-sym-link";
import 'react-modal-playground-sym-link/dist/index.css';

function Wallet() {
  return (
    <div style={{ 
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '80px'
    }}>
      <Navbar />
      <div style={{
        flex: 1,
        width: '100%',
        padding: '0px',
        margin: '0px'
      }}>
        <App />
      </div>
      <footer className="home-footer">
        <p>© 2024 Sistema de Pagos. Todos los derechos reservados.</p>
      </footer>
    </div>
  );
}

export default Wallet; 