import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import logo from "../images/logo.png";
import "../css/payment-select.css";
import QR from "../images/QR.png";
import Menu from "../components/menu-icon";
import Back from "../components/back";

function PaymentSelect() {
  const initialTime = 15 * 60;
  const [timeLeft, setTimeLeft] = useState(initialTime);

  useEffect(() => {
    if (timeLeft <= 0) return;

    const timerId = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(timerId);
  }, [timeLeft]);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  const progressPercentage = ((initialTime - timeLeft) / initialTime) * 100;
  return (
    <main className="flex min-h-screen flex-col items-center ">
      <div className="logo justify-between">
        <img src={logo} alt="logo" width={150} height={100} />
      </div>
      <div>
      <Menu/>
      <Back/>
      </div>
      <div className="container-payment-select items-center">
        <div className="container-payment-text-select">
          <div className="container-text-payment">
            <div className="container-text-select">
              <span className="text-payment-select">
                Escanea el QR para realizar tu pago
              </span>
            </div>
            <div className="container-select-payment">
              <div className="container-text-method-payment">
                <span>Pagar con:</span>
              </div>
              <div className="container-select-method-payment">
                <select className="payments-select">
                  <option>Lightnig</option>
                  <option>Example</option>
                </select>
              </div>
            </div>
            <div className="container-image">
            <Link to="/payment/completed">
              <img src={QR} alt="QR" />
            </Link>
            </div>
            <div className="container-counter">
              <span className="text-counter">
                Expira en: {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
              </span>
              <div
                style={{
                  width: "178px",
                  backgroundColor: "#F0EDED",
                  borderRadius: "50px",
                  marginTop: "20px",
                }}
              >
                <div
                  style={{
                    width: `${
                      progressPercentage > 100 ? 100 : progressPercentage
                    }%`,
                    backgroundColor: "#190E5F",
                    height: "21px",
                    borderRadius: "50px",
                  }}
                />
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </main>
  );
}
export default PaymentSelect;
