import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Icono } from '../images/menu.svg';
const MenuButton = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/menu');
    };
    return (
        <button style={buttonStyle}  onClick={handleClick}>
          <Icono width="33" height="30" />
        </button>
      );
    };

    const buttonStyle = {
        border: 'none',
        borderRadius: '5px',
        backgroundColor: '#007bff00',
        color: '#ffffff',
        fontSize: '16px',
        cursor: 'pointer',
        position: 'absolute',
        left: '75%',
        top: '6.5%',
      };

export default MenuButton;