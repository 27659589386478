import logo from "../images/logo.png";
import "../css/menu.css";
import Menuu from "../components/menu-icon";
import Back from "../components/back";
import { useNavigate } from 'react-router-dom';

function Menu() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/payment/history');
};
const handleLogout = () => {
  navigate('/login');
};
    return (
      <main className="flex min-h-screen flex-col items-center ">
       <div className="logo justify-between">
        <img src={logo} alt="logo" width={150} height={100} />
      </div>
      <div>
      <Menuu/>
      <Back/>
      </div>
      <div className="container-button">
      <button onClick={handleClick} className="transactions">Transacciones</button>
      <button onClick={handleLogout} className="logout">Cerrar sesión</button>
      </div>
      <div className="container-menu items-center">
      </div>
     
      </main>
    );
  }
  export default Menu;