import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import logo from "../images/logo.png";
import Menu from "../components/menu-icon";
import Back from "../components/back";
import "../css/payment.css";

function Payment() {
  const [inputValue, setInputValue] = useState("");
  const navigate = useNavigate();

  const handleButtonClick = (value) => {
    if (value === "<") {
      setInputValue(inputValue.slice(0, -1));
    } else if (value === "Borrar") {
      setInputValue("");
    } else {
      setInputValue(inputValue + value);
    }
  };

  const handlePayment = () => {
    navigate('/payment/select');
  };
  return (
    <main className="flex min-h-screen flex-col items-center ">
      <div className="logo justify-between">
        <img src={logo} alt="logo" width={150} height={100} />
      </div>
      <div>
      <Menu/>
      <Back/>
      </div>
      <div className="container-payment items-center">
        <div className="container-payment-text">
          <div className="container-text-payment">
            <div className="container-text">
              <span className="text-payment">GTQ
              </span>
            </div>
            <div className="container-text1">
              <span className="text-payment1">Monto Total</span>
            </div>
          </div>
          <div>
            <div className="container-text2">
              <span className="mount-text"> {inputValue}</span>
            </div>
          </div>
        </div>
        <div className="container-payment-text1">
          <div className="container-panel-numeric">
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gap: "10px",
                }}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, ".", 0, "<"].map((number) => (
                  <button
                    key={number}
                    onClick={() => handleButtonClick(number)}
                    style={buttonStyle}
                  >
                    {number}
                  </button>
                ))}
              </div>
              <button
                onClick={() => handleButtonClick("Borrar")}
                style={{ color: "red", ...buttonStyle1 }}
              >
                Borrar
              </button>
              <button
                onClick={handlePayment}
                style={{ color: "green", ...buttonStyle2 }}
              >
                Pagar
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
const buttonStyle = {
  padding: "8px",
  fontSize: "22px",
  background: "none",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
  color: "#190E5F",
  // eslint-disable-next-line
  fontSize: "50px",
};

const buttonStyle1 = {
  padding: "8px",
  fontSize: "22px",
  background: "none",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
  // eslint-disable-next-line
  fontSize: "30px",
  position: "relative",
  top: "5px",
  right: "40px",
};
const buttonStyle2 = {
  padding: "8px",
  fontSize: "22px",
  background: "none",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
  // eslint-disable-next-line
  fontSize: "30px",
  position: "relative",
  top: "5px",
  left: "40px",
};

export default Payment;
