import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import "../css/remesas.css";

function Remesas() {
  return (
    <div className="remesas-container">
      <Navbar />
      <div className="content">
        <h1>Remesas Internacionales</h1>
        <div className="remesas-section">
          <div className="remesas-card">
            <h2>Envía dinero de manera rápida, segura y con comisiones competitivas</h2>
            <p>En nuestra plataforma, hemos simplificado el proceso de envío de dinero a familiares y
              colaboradores. Gracias a nuestra tecnología avanzada y la integración con nuestra tarjeta
              Mastercard Black, transferir fondos es más fácil que nunca. Ofrecemos un servicio que combina
              eficiencia y transparencia, ayudando a nuestros usuarios a mantener el control de sus finanzas.</p>

            <div className="remesas-features">
              <div className="feature">
                <h3>🌎 Global y Accesible</h3>
                <p>Transfiere fondos desde cualquier parte del mundo a cualquier usuario
                  con nuestra tarjeta Mastercard Black, sin importar la distancia.</p>
              </div>
              <div className="feature">
                <h3>⚡ Transferencias Inmediatas</h3>
                <p>Envía dinero de manera instantánea a cualquier persona que
                  posea nuestra tarjeta, sin esperas prolongadas.</p>
              </div>
              <div className="feature">
                <h3>💰 Comisiones Competitivas</h3>
                <p>Olvídate de las tarifas exorbitantes. Ofrecemos una de las
                  tasas más bajas del mercado, manteniendo la transparencia en todo momento.</p>
              </div>
              <div className="feature">
                <h3>🔒 Seguridad Garantizada</h3>
                <p>Nuestras transacciones están protegidas por sistemas de
                  encriptación de última generación, asegurando que tu dinero llegue a su destino sin
                  inconvenientes.</p>
              </div>
              <div className="feature">
                <h3>📱 Proceso Sencillo</h3>
                <p>Realizar una remesa es tan fácil como unos cuantos clics en nuestra app 
                   o página web. No necesitas complicadas verificaciones o procesos engorrosos.</p>
              </div>
            </div>
            <div className="remesas-benefits">
              <h3>Beneficios de Usar Nuestro Servicio de Remesas</h3>
              <div className="benefits-grid">
                <div className="feature">
                  <h3>💵 Sin sorpresas</h3>
                  <p>Lo que ves es lo que pagas; sin cargos ocultos.</p>
                </div>
                <div className="feature">
                  <h3>🤝 Flexibilidad total</h3>
                  <p>Compatible tanto para enviar a individuos como a pequeños negocios que usen nuestra tarjeta.</p>
                </div>
                <div className="feature">
                  <h3>⏱️ Ahorra tiempo y dinero</h3>
                  <p>La combinación de tarifas competitivas y procesos automatizados hace que enviar dinero sea más eficiente y accesible.</p>
                </div>
              </div>
            </div>

            <div className="remesas-steps">
              <h3>¿Cómo funciona?</h3>
              <div className="steps-grid">
                <div className="step">
                  <div className="step-number">1</div>
                  <p>Regístrate y verifica tu cuenta</p>
                </div>
                <div className="step">
                  <div className="step-number">2</div>
                  <p>Selecciona el país destino</p>
                </div>
                <div className="step">
                  <div className="step-number">3</div>
                  <p>Ingresa el monto a enviar</p>
                </div>
                <div className="step">
                  <div className="step-number">4</div>
                  <p>¡Listo! Tu dinero será enviado</p>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Remesas; 