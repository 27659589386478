import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from "../images/logo.png";
import "../css/navbar.css";

function Navbar() {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-logo">
          <img 
            src={logo} 
            alt="logo" 
            onClick={() => navigate('/home')} 
            style={{
              cursor: 'pointer',
              width: '180px',
              height: 'auto'
            }} 
          />
        </div>
        
        <button className="hamburger-menu" onClick={toggleMenu}>
          <span className="hamburger-line"></span>
          <span className="hamburger-line"></span>
          <span className="hamburger-line"></span>
        </button>

        <div className={`navbar-links ${isMenuOpen ? 'active' : ''}`}>
          <button onClick={() => navigate('/quienes-somos')} className="nav-link">
            Quiénes Somos
          </button>
          <button 
            onClick={() => navigate('/wallet/wallet-home')} 
            className="nav-link"
          >
            Wallet
          </button>
          <button onClick={() => navigate('/tarjetas')} className="nav-link">
            Tarjetas
          </button>
          <button onClick={() => navigate('/remesas')} className="nav-link">
            Remesas
          </button>
          <button onClick={() => navigate('/contacto')} className="nav-link">
            Contáctanos
          </button>
        </div>
      </div>
    </nav>
  );
}

export default Navbar; 