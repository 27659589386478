import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Icono } from '../images/back.svg';
const BackButton = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(-1);
    };
    return (
        <button style={buttonStyle}  onClick={handleClick}>
          <Icono width="34" height="22" />
        </button>
      );
    };

    const buttonStyle = {
        border: 'none',
        borderRadius: '5px',
        backgroundColor: '#007bff00',
        color: '#ffffff',
        fontSize: '16px',
        cursor: 'pointer',
        position: 'absolute',
        left: '14%',
        top: '7%',
      };

export default BackButton;