import check from "../images/check.png"
import logo from "../images/logo.png";
import "../css/payment-completed.css";
import Menu from "../components/menu-icon";
import Back from "../components/back";
function PaymentCompleted() {
  
  return (
    <main className="flex min-h-screen flex-col items-center ">
      <div className="logo justify-between">
        <img src={logo} alt="logo" width={150} height={100} />
      </div>
      <div>
      <Menu/>
      <Back/>
      </div>
      <div className="container-payment-select items-center">
        <div className="container-payment-text-completed">
          <div className="container-text-payment">
          <div className="container-check">
          <img src={check} alt="check"/>
          </div>
            <div className="container-text-payment-completed">
              <span className="text-payment-completed">
                Estado: 
                <span className="text-bold">completado</span>
              </span>
              <span className="text-payment-completed">
               Monto: 
               <span className="text-adjust">1000.00 GTQ</span>
              </span>
              <span className="text-payment-completed">
              Identificadora de transacción #: 
              <span className="text-adjust1">(012345678)</span>
              </span>
              <span className="text-payment-completed">
              Network:
              <span className="text-adjust">Lightning</span>
              </span>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </main>
  );
}
export default PaymentCompleted;
