import { useNavigate } from "react-router-dom";
import logo from "../images/logo.png";
import "../css/login.css";

function Login() {
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    navigate("/payment");
  };

  return (
    <main className="flex min-h-screen flex-col items-center">
      <div className="logo1 justify-between">
        <img src={logo} alt="logo" width={150} height={100} />
      </div>

      <div className="container-login items-center">
        <div className="title-container">
          <span className="text-sesion">INICIA SESIÓN</span>
        </div>

        <div className="container-login-camps">
          <div>
            <form className="camps" onSubmit={handleLogin}>
              <input
                className="input-login"
                type="text"
                placeholder="Grupo id"
              />
              <input className="input-login" type="text" placeholder="Pos id" />
              <button className="button-login" type="submit">
                Ingresar
              </button>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Login;
