import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import '../css/contact-form.css';
import emailjs from '@emailjs/browser';
import Navbar from './Navbar';

function ContactForm() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    nombreCompleto: '',
    empresa: '',
    email: '',
    telefono: '',
    detalles: ''
  });
  const [mensajeEnviado, setMensajeEnviado] = useState(false);

  useEffect(() => {
    emailjs.init("TWScH2TcjSTaWOnm2");
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const templateParams = {
        nombre_completo: formData.nombreCompleto,
        empresa: formData.empresa || 'No especificada',
        email: formData.email,
        telefono: formData.telefono,
        detalles: formData.detalles || 'Sin detalles adicionales',
        reply_to: formData.email
      };

      await emailjs.send(
        'service_wxbx56s',
        'template_3c9or07',
        templateParams,
        'TWScH2TcjSTaWOnm2'
      );

      setMensajeEnviado(true);
      setTimeout(() => {
        navigate('/');
      }, 3000);
      
    } catch (error) {
      console.error('Error al enviar el formulario:', error);
      alert('Hubo un error al enviar el mensaje. Por favor, intente nuevamente.');
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <div className="contact-page">
      <Navbar />
      <div className="form-section">
        <div className="form-overlay">
          <div className="form-container">
            <h2>Contáctenos</h2>
            
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Nombre completo *</label>
                <input
                  type="text"
                  name="nombreCompleto"
                  required
                  onChange={handleChange}
                />
              </div>

              <div className="form-group">
                <label>Empresa (opcional)</label>
                <input
                  type="text"
                  name="empresa"
                  onChange={handleChange}
                />
              </div>

              <div className="form-group">
                <label>Email *</label>
                <input
                  type="email"
                  name="email"
                  required
                  onChange={handleChange}
                />
              </div>

              <div className="form-group">
                <label>Teléfono *</label>
                <input
                  type="tel"
                  name="telefono"
                  required
                  onChange={handleChange}
                />
              </div>

              <div className="form-group">
                <label>Detalles de su consulta</label>
                <textarea
                  name="detalles"
                  placeholder="Cuéntenos más sobre su consulta..."
                  onChange={handleChange}
                />
              </div>

              <button type="submit" className="submit-button">
                Enviar
              </button>
            </form>

            {mensajeEnviado && (
              <div className="mensaje-exito">
                ¡Mensaje enviado con éxito! Serás redirigido en unos segundos...
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ContactForm; 