import logo from "../images/logo.png";
import "../css/payment-history.css";
import Menu from "../components/menu-icon";
import Back from "../components/back";
function PaymentHistory() {
  
  return (
    <main className="flex min-h-screen flex-col items-center ">
      <div className="logo justify-between">
       <img src={logo} alt="logo" width={150} height={100} />
      </div>
      <div>
      <Menu/>
      <Back/>
      </div>
      <div className="container-payment-history items-center">
        <div className="container-payment-text-history">
          <div className="container-text-payment">
            <div className="container-text-payment-history">
              <span className="text-payment-history">
                Monto: 
                <span className="text-adjust">1000.00 GTQ</span>
              </span>
              <span className="text-payment-history">
               Estado: 
               <span className="text-bold">completado</span>
              </span>
              <span className="text-payment-history">
              Fecha: 
              <span className="text-adjust">5/01/2023 12:56</span>
              </span>
            </div>
          </div>
          <div></div>
        </div>
        <div className="container-payment-text-history">
          <div className="container-text-payment">
            <div className="container-text-payment-history">
              <span className="text-payment-history">
                Monto: 
                <span className="text-adjust">1000.00 GTQ</span>
              </span>
              <span className="text-payment-history">
               Estado: 
               <span className="text-bold">completado</span>
              </span>
              <span className="text-payment-history">
              Fecha: 
              <span className="text-adjust">5/01/2023 14:03</span>
              </span>
            </div>
          </div>
          <div></div>
        </div>
        <div className="container-payment-text-history">
          <div className="container-text-payment">
            <div className="container-text-payment-history">
              <span className="text-payment-history">
                Monto: 
                <span className="text-adjust">1000.00 GTQ</span>
              </span>
              <span className="text-payment-history">
               Estado: 
               <span className="text-bold1">expirado</span>
              </span>
              <span className="text-payment-history">
              Fecha: 
              <span className="text-adjust">5/01/2023 18:34</span>
              </span>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </main>
  );
}
export default PaymentHistory;
