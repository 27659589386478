// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Loader from './pages/Loader';
import Login from './pages/login';
import Payment from './pages/Payment';
import PaymentSelect from './pages/payment-select';
import PaymentCompleted from './pages/payment-completed';
import PaymentHistory from './pages/payment-history';
import Menu from './components/menu';
import Home from './pages/Home';
import QuienesSomos from './pages/QuienesSomos';
import Remesas from './pages/Remesas';
import ContactForm from './components/ContactForm';
import Wallet from './pages/Wallet';
import Tarjetas from './pages/Tarjetas';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Loader />} />
          <Route path="/home" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/payment/select" element={<PaymentSelect />} />
          <Route path="/payment/completed" element={<PaymentCompleted />} />
          <Route path="/payment/history" element={<PaymentHistory />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/quienes-somos" element={<QuienesSomos />} />
          <Route path="/contacto" element={<ContactForm />} />
          <Route path="/remesas" element={<Remesas />} />
          <Route path="/wallet/*" element={<Wallet />} />
          <Route 
            path="/wallet-module" 
            element={
              <Navigate 
                to={process.env.REACT_APP_WALLET_MODULE_URL || "http://localhost:5173"} 
                replace 
              />
            } 
          />
          <Route path="/tarjetas" element={<Tarjetas />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;